import React, { Component } from "react";
import { FaVolumeOff } from "react-icons/fa";
import { FaVolumeUp } from "react-icons/fa";
import DraggableCore from "react-draggable";
import styles from "./VolumeControl.module.css";

export class VolumeControl extends Component {
  constructor(props) {
    super(props);

    this.volumeBarRef = React.createRef();
    this.volumeHandleRef = React.createRef();
    this.volumeDraggableRef = React.createRef();

    this.handleStart = this.handleStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }

  handleDrag(event, data) {}

  handleStart(event, data) {}

  handleStop(event, data) {
    // get percent
    var bar = this.volumeBarRef.current.getBoundingClientRect();
    var handle = this.volumeHandleRef.current.getBoundingClientRect();

    // the - 30 is to account for the offset of the hit box
    var percent = (handle.x - (bar.x - 30)) / bar.width;

    // override / reset the transform applied by Draggable
    var currentStyle = this.volumeHandleRef.current.style;
    this.volumeHandleRef.current.style = {
      ...currentStyle,
      transform: "translate(0px, 0px)",
    };
    this.volumeDraggableRef.current.state.x = 0;

    this.props.SetVolume(percent);
  }

  render() {
    return (
      <div className={styles.VolumeContainer}>
        <FaVolumeOff className={styles.VolumeOff} />
        <div ref={this.volumeBarRef} className={styles.Volume}>
          <div
            id="volume-bar"
            className={styles.Bar}
            style={{ width: this.props.volume * 100 + "%" }}
          ></div>
          <DraggableCore
            ref={this.volumeDraggableRef}
            axis="x"
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}
            bounds="parent"
          >
            <div
              ref={this.volumeHandleRef}
              className={styles.Handle}
              style={{ left: this.props.volume * 100 + "%" }}
            >
              <div className={styles.HandleVisual} />
            </div>
          </DraggableCore>
        </div>
        <FaVolumeUp className={styles.VolumeUp} />
      </div>
    );
  }
}
