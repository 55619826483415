import React, { Component } from "react";
import DraggableCore from "react-draggable";

import { Drawer } from "../Drawer";
import { CoverImage } from "../CoverImage";
import { TrackInfo } from "../TrackInfo";
import { VolumeControl } from "../VolumeControl";
import { AudioProgress } from "../AudioProgress";
import { Playlist } from "../Playlist";
import { PlayPauseButton } from "../PlayPauseButton";
import { PrevButton } from "../PrevButton";
import { NextButton } from "../NextButton";
import { BackButton } from "../BackButton";
import { CurrentlyPlaying } from "../CurrentlyPlaying";
import { MobileTracklist } from "../MobileTracklist";

import { ReactComponent as UpArrow } from "../../assets/up-arrow.svg";

import styles from "./MobileMusicPlayer.module.css";

export class MobileMusicPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlaying: false,
      showingTrack: false,
      selectedTrackIndex: 0,
      currentTime: "0:00",
      timeRemaining: "0:00",
      currentTrack: { artist: "", title: "" },
      progress: 0,
      volume: 1.0,
      coverImage: this.props.cover,
      allowedAccess: false,
    };

    this.OnPlayPauseButtonClicked = this.OnPlayPauseButtonClicked.bind(this);
    this.OnPrevButtonClicked = this.OnPrevButtonClicked.bind(this);
    this.OnNextButtonClicked = this.OnNextButtonClicked.bind(this);
    this.OnPlaylistItemClicked = this.OnPlaylistItemClicked.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.setTime = this.setTime.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.setTrackByTitle = this.setTrackByTitle.bind(this);
  }

  async componentDidMount() {
    if (this.props.tracks && this.props.tracks.length > 0) {
      this.setTrack(this.state.selectedTrackIndex);
    }
  }

  updateTimeRemaining() {
    const timeRemaining = this.audio.duration - this.audio.currentTime;

    var s = parseInt(timeRemaining % 60);
    var m = parseInt(timeRemaining / 60) % 60;
    if (isNaN(m)) {
      return;
    }
    if (isNaN(s)) {
      return;
    }
    if (s < 10) {
      s = "0" + s;
    }

    this.setState({ timeRemaining: "-" + m + ":" + s });
  }

  updateCurrentTime() {
    var s = parseInt(this.audio.currentTime % 60);
    var m = parseInt(this.audio.currentTime / 60) % 60;
    if (s < 10) {
      s = "0" + s;
    }
    this.setState({ currentTime: "" + m + ":" + s });
  }

  onTimeUpdate(event) {
    this.updateTimeRemaining();
    this.updateCurrentTime();

    var percent = 0;
    if (this.audio.currentTime > 0) {
      percent = (100 / this.audio.duration) * this.audio.currentTime;
    }

    this.setState({ progress: percent });
  }

  setTime(percent) {
    this.audio.currentTime = percent * this.audio.duration;
  }

  setTrackByTitle(title) {
    var index = -1;

    for (var i = 0; i < this.props.tracks.length; i++) {
      if (this.props.tracks[i].title === title) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      this.setTrack(index);
      this.play();
    }
  }

  setTrack(trackIndex) {
    // TODO: generate signed one-time URL for the mp3
    if (this.props.tracks.length < 1) {
      return;
    }

    const track = this.props.tracks[trackIndex];
    if (this.audio) {
      this.audio.pause();
      delete this.audio;
    }

    this.audio = new Audio(track.url);
    this.audio.pause();

    this.audio.addEventListener("timeupdate", this.onTimeUpdate);
    this.audio.addEventListener("ended", (event) => {
      this.next();
    });

    this.setState({ selectedTrackIndex: trackIndex });
    this.setState({
      coverImage: this.props.defaultCoverImage,
    });
    this.setState({ currentTrack: track });

    this.updateTimeRemaining();
  }

  OnPlaylistItemClicked(event, title) {
    this.setTrackByTitle(title);
  }

  OnPlayPauseButtonClicked() {
    if (this.state.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  OnPrevButtonClicked() {
    this.prev();
  }

  OnNextButtonClicked() {
    this.next();
  }

  setVolume(percent) {
    if (percent < 0.05) {
      percent = 0.0;
    }
    if (percent > 0.9) {
      percent = 1.0;
    }
    if (this.audio) {
      this.audio.volume = percent;
      this.setState({ volume: percent });
    }
  }

  play() {
    if (this.audio) {
      this.audio.play();
      this.setState({ isPlaying: true });
    } else {
      this.setTrack(0);
      this.audio.play();
      this.setState({ isPlaying: true });
    }
  }

  pause() {
    if (this.audio) {
      this.audio.pause();
    }
    this.setState({ isPlaying: false });
  }

  getPrevTrackIndex() {
    var index = this.props.tracks.length - 1;
    if (this.state.selectedTrackIndex - 1 > -1) {
      index = this.state.selectedTrackIndex - 1;
    }

    return index;
  }

  prev() {
    this.pause();
    const prevTrackIndex = this.getPrevTrackIndex();
    this.setTrack(prevTrackIndex);
    this.play();
  }

  getNextTrackIndex() {
    var index = 0;
    if (this.state.selectedTrackIndex + 1 < this.props.tracks.length) {
      index = this.state.selectedTrackIndex + 1;
    }

    return index;
  }

  next() {
    this.pause();
    const nextTrackIndex = this.getNextTrackIndex();
    this.setTrack(nextTrackIndex);
    this.play();
  }

  render() {
    if (this.props.allowedAccess) {
      if (this.state.showingTrack) {
        // show single track view
        return (
          <div
            style={{
              height: "100vh",
              padding: "0px",
              margin: "0px",
              backgroundColor: "red",
              overflow: "hidden",
              maxWidth: "712px",
              backgroundColor: "#222222",
              transitionProperty: "all",
              transitionDuration: "0.5s",
              transitionTimingFunction: "cubic-bezier(0, 1, 0.5, 1)",
            }}
          >
            <div
              ref={this.divRef}
              style={{
                height: "100vh",
                width: "100%",
                zIndex: "-1",
                color: "white",
                margin: "0px",
                background: "black",
                backgroundImage: `linear-gradient(${this.props.bgcolor}, black)`,
                backgroundAttachment: "fixed",
                fontFamily: "sans-serif",
              }}
            >
              <div style={{ justifyContent: "center" }}>
                <span
                  style={{
                    display: "flex",
                    color: "white",
                    fontFamily: "sans-serif",
                    width: "inherit",
                    minWidth: "inherit",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  {this.props.albumTitle}
                </span>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CoverImage
                    style={{
                      pointerEvents: "none",
                      justifyContent: "center",
                      zIndex: "0",
                      display: "flex",
                      width: "280px",
                      height: "280px",
                      transition: "opacity .1s ease",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      opacity: `${this.state.opacity}`,
                      transform: `scale(${this.state.opacity}, ${this.state.opacity})`,
                    }}
                    image={this.props.defaultCoverImage}
                  />
                </div>
                <div style={{ height: "30px" }} />
                <div
                  style={{
                    paddingLeft: "30px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.25em",
                    }}
                  >
                    {this.state.currentTrack.title}
                  </div>
                  <br />
                  <div
                    style={{
                      color: "#AAAAAA",
                    }}
                  >
                    {this.state.currentTrack.artist}
                  </div>
                </div>

                <AudioProgress
                  progress={this.state.progress}
                  currentTime={this.state.currentTime}
                  timeRemaining={this.state.timeRemaining}
                  SetTime={this.setTime}
                />
                <div
                  style={{
                    height: "50px",
                  }}
                />
                <div style={{ backgroundColor: "transparent" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <PrevButton
                      {...this.props}
                      width="50"
                      height="50"
                      fill={this.props.buttonColor}
                      OnPrevButtonClicked={this.OnPrevButtonClicked}
                    />
                    <PlayPauseButton
                      {...this.props}
                      fill={this.props.buttonColor}
                      width="50"
                      height="50"
                      isPlaying={this.state.isPlaying}
                      OnPlayPauseButtonClicked={this.OnPlayPauseButtonClicked}
                    />
                    <NextButton
                      {...this.props}
                      width="50"
                      height="50"
                      fill={this.props.buttonColor}
                      OnNextButtonClicked={this.OnNextButtonClicked}
                    />
                  </div>
                </div>
              </div>
            </div>
            <UpArrow
              fill={this.props.buttonColor}
              width="40px"
              height="40px"
              style={{
                position: "fixed",
                left: "15px",
                top: "15px",
                transform: "rotate(180deg)",
              }}
              onClick={() => {
                this.setState({ showingTrack: false });
              }}
            />
          </div>
        );
      } else {
        // show track listing view
        return (
          <div
            style={{
              height: "100vh",
              padding: "0px",
              margin: "0px",
              backgroundColor: "red",
              overflow: "hidden",
              maxWidth: "712px",
              backgroundColor: "#222222",
              transition:
                "height 0.25s ease-in-out, background-color 0.25s ease-in-out",
            }}
          >
            <MobileTracklist
              albumCover={this.props.defaultCoverImage}
              albumTitle={this.props.albumTitle}
              releaseYear={this.props.releaseYear}
              tracks={this.props.tracks}
              artist={this.props.artist}
              artistImage={this.props.artistImage}
              releaseDate={this.props.releaseDate}
              playtime={this.props.playtime}
              bgcolor={this.props.bgcolor}
              buttonColor={this.props.buttonColor}
              trackHighlightColor={this.props.trackHighlightColor}
              itemHeight="50"
              currentTrack={this.state.currentTrack}
              OnPlaylistItemClicked={this.OnPlaylistItemClicked}
              OnDownloadTrackClicked={this.props.OnDownloadTrackClicked}
              OnLikeAlbumClicked={this.props.OnLikeAlbumClicked}
              OnDownloadAlbumClicked={this.props.OnDownloadAlbumClicked}
              OnArtistClicked={this.props.OnArtistClicked}
            />

            <CurrentlyPlaying
              height="50"
              cover={this.state.currentTrack.coverImage}
              artist={this.state.currentTrack.artist}
              trackTitle={this.state.currentTrack.title}
              isPlaying={this.state.isPlaying}
              buttonColor={this.props.buttonColor}
              OnCurrentlyPlayingClicked={() => {
                this.setState({ showingTrack: true });
              }}
              progress={this.state.progress}
              OnPlayPauseButtonClicked={(e) => {
                this.OnPlayPauseButtonClicked();
              }}
              currentTime={this.state.currentTime}
              timeRemaining={this.state.timeRemaining}
              SetTime={this.setTime}
            />
          </div>
        );
      }
    } else {
      return <div>You are not allowed to access</div>;
    }
  }
}

export default MobileMusicPlayer;
