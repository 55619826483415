import React, { Component } from "react";
import styles from "./DownloadButton.module.css";
import { ReactComponent as DownloadButtonSvg } from "../../assets/download.svg";
import { ReactComponent as DownloadOutlineButtonSvg } from "../../assets/download-outline.svg";

export class DownloadButton extends Component {
  render() {
    if (this.props.downloaded) {
      return (
        <div
          style={{
            minWidth: this.props.width,
            minHeight: this.props.height,
          }}
          onClick={this.props.onClick}
        >
          <DownloadButtonSvg
            fill={this.props.fill}
            width={this.props.width}
            height={this.props.height}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            minWidth: this.props.width,
            minHeight: this.props.height,
          }}
          onClick={this.props.onClick}
        >
          <DownloadOutlineButtonSvg
            fill={this.props.fill}
            stroke={this.props.stroke}
            width={this.props.width}
            height={this.props.height}
          />
        </div>
      );
    }
  }
}
