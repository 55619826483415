import React, { Component } from "react";
import DraggableCore from "react-draggable";
import styles from "./AudioProgress.module.css";

class Duration extends Component {
  render() {
    return (
      <div>
        <span className={styles.CurrentTime}>{this.props.currentTime}</span>
        <span className={styles.TimeRemaining}>{this.props.timeRemaining}</span>
      </div>
    );
  }
}

export class AudioProgress extends Component {
  constructor(props) {
    super(props);

    this.progressBarRef = React.createRef();
    this.progressHandleRef = React.createRef();
    this.draggableRef = React.createRef();

    this.handleStart = this.handleStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }

  handleDrag(event, data) {}

  handleStart(event, data) {}

  handleStop(event, data) {
    // get percent
    var bar = this.progressBarRef.current.getBoundingClientRect();
    var handle = this.progressHandleRef.current.getBoundingClientRect();

    // the - 30 is to account for the offset of the hit box
    var percent = (handle.x - (bar.x - 30)) / bar.width;

    // set position in song
    this.props.SetTime(percent);

    // override / reset the transform applied by Draggable
    var currentStyle = this.progressHandleRef.current.style;
    this.progressHandleRef.current.style = {
      ...currentStyle,
      transform: "translate(0px, 0px)",
    };
    this.draggableRef.current.state.x = 0;
  }

  render() {
    return (
      <div>
        <div ref={this.progressBarRef} className={styles.Container}>
          <div
            className={styles.Bar}
            style={{ width: this.props.progress + "%" }}
          ></div>
          <DraggableCore
            ref={this.draggableRef}
            axis="x"
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}
            bounds="parent"
          >
            <div
              ref={this.progressHandleRef}
              className={styles.Handle}
              style={{ left: this.props.progress + "%" }}
            >
              <div className={styles.HandleVisual} />
            </div>
          </DraggableCore>
        </div>
        <Duration
          currentTime={this.props.currentTime}
          timeRemaining={this.props.timeRemaining}
        />
      </div>
    );
  }
}
