import "./CircleImage.css";

export const CircleImage = ({ image, width, height, style, onClick }) => {
  return (
    <div className="CircleImage" style={style} onClick={onClick}>
      <img
        src={image}
        alt="Album Cover"
        width={width}
        height={height}
        style={{
          ...style,
          width: "26px",
          height: "26px",
          borderRadius: "13px",
        }}
      />
    </div>
  );
};

export default CircleImage;
