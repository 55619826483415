import React, { Component } from "react";
import styles from "./MobileTracklist.module.css";
import { ExplicitIcon } from "../ExplicitIcon";
import { CoverImage } from "../CoverImage";
import { LikeButton } from "../LikeButton";
import { DownloadButton } from "../DownloadButton";
import { CircleImage } from "../CircleImage";
import { FaGrinTongueSquint } from "react-icons/fa";

export class PlaylistItem extends Component {
  render() {
    var explicitIcon = null;
    if (this.props.explicit) {
      var explicitIcon = (
        <ExplicitIcon fill="lightgray" width="20" height="20" />
      );
    }

    const title = this.props.isActive ? (
      <div style={{ color: this.props.trackHighlightColor }}>
        {this.props.title}
      </div>
    ) : (
      <div>{this.props.title}</div>
    );

    return (
      <div
        onClick={(e) => this.props.OnPlaylistItemClicked(e, this.props.title)}
        key={this.props.title}
        song={this.props.title}
        cover={this.props.title}
        artist={this.props.title}
        style={{
          fontFamily: "sans-serif",
          display: "block",
          padding: "5px",
          float: "left",
          minWidth: "100%",
          width: "100%",
          height: this.props.height,
          justifyContent: "space-between",
        }}
      >
        {title}
        <span style={{ display: "inline-flex" }}>
          <DownloadButton
            fill={this.props.buttonColor}
            stroke={this.props.buttonColor}
            width="20"
            height="20"
            downloaded={this.props.downloaded}
            onClick={(e) => {
              e.stopPropagation();
              this.props.OnDownloadTrackClicked(e, this.props.title);
            }}
          />
        </span>
        <div style={{ display: "inline-flex" }}>&nbsp;{explicitIcon}</div>
        <span
          style={{
            fontSize: "0.75em",
            color: "gray",
          }}
        >
          {this.props.artist}
        </span>
      </div>
    );
  }
}

export class MobileTracklist extends Component {
  constructor(props) {
    super(props);

    this.divRef = React.createRef();

    this.state = {
      opacity: "1",
    };
  }

  componentDidMount() {
    this.divRef.current.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    this.divRef.current.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const opacity = Math.max(0, (300 - this.divRef.current.scrollTop) / 300);
    this.setState({ opacity });
  };

  render() {
    var setOne = false;
    var isActive = false;
    const listItems = this.props.tracks.map((track) => {
      var isActive = false;
      if (this.props.currentTrack) {
        if (this.props.currentTrack.title === track.title) {
          isActive = true;
        }
      }

      if (!setOne) {
        track.isActive = true;
        setOne = true;
      }
      if (this.props.currentTrack) {
        if (this.props.currentTrack.title === track.title || !setOne) {
        }
      }

      return (
        <PlaylistItem
          OnPlaylistItemClicked={this.props.OnPlaylistItemClicked}
          OnDownloadTrackClicked={this.props.OnDownloadTrackClicked}
          key={track.title + Math.random()}
          title={track.title}
          song={track.song}
          artist={track.artist}
          downloaded={track.downloaded}
          explicit={track.explicit}
          isActive={isActive}
          height={this.props.itemHeight}
          buttonColor={this.props.buttonColor}
          trackHighlightColor={this.props.trackHighlightColor}
        />
      );
    });

    return (
      <div
        ref={this.divRef}
        className={styles.PlaylistContainer}
        style={{
          height: "calc(100vh - 48px)",
          width: "100%",
          zIndex: "-1",
          color: "white",
          margin: "0px",
          background: "black",
          backgroundImage: `linear-gradient(${this.props.bgcolor}, black)`,
          backgroundAttachment: "fixed",
          fontFamily: "sans-serif",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <CoverImage
            style={{
              pointerEvents: "none",
              justifyContent: "center",
              zIndex: "0",
              position: "fixed",
              width: "280px",
              height: "280px",
              transition: "opacity .1s ease",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              opacity: `${this.state.opacity}`,
              transform: `scale(${this.state.opacity}, ${this.state.opacity})`,
            }}
            image={this.props.albumCover}
          />
        </div>
        <div
          style={{
            zIndex: "100",
          }}
        >
          <div
            style={{
              height: "300px",
            }}
          />
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              padding: "5px",
            }}
          >
            {this.props.albumTitle}
          </span>
          <br />
          <div
            style={{
              height: "26px",
              padding: "5px",
              lineHeight: "26px",
              fontSize: "0.9em",
              float: "left",
              marginTop: "auto",
              marginBottom: "auto",
              verticalAlign: "middle",
              display: "inline-block",
            }}
          >
            <span
              style={{
                padding: "5px",
              }}
            >
              <CircleImage
                image={this.props.artistImage}
                onClick={this.props.OnArtistClicked}
                style={{
                  display: "inline-flex",
                  height: "26px",
                  verticalAlign: "middle",
                }}
              />
            </span>
            <span
              style={{
                lineHeight: "normal",
                padding: "5px",
                verticalAlign: "middle",
              }}
              onClick={this.props.OnArtistClicked}
            >
              &nbsp;{this.props.artist}
            </span>
          </div>
          <div style={{ clear: "left", display: "block", padding: "5px" }}>
            <span style={{ color: "lightgray", fontSize: "0.75em" }}>
              Album · {this.props.releaseYear}
            </span>
            <br />
            <span
              style={{
                display: "inline-flex",
                padding: "5px",
                marginRight: "20px",
              }}
            >
              <LikeButton
                fill={this.props.buttonColor}
                stroke={this.props.buttonColor}
                liked={false}
                width="20"
                height="20"
                onClick={this.props.OnLikeAlbumClicked}
              />
            </span>
            <span style={{ display: "inline-flex" }}>
              <DownloadButton
                fill={this.props.buttonColor}
                stroke={this.props.buttonColor}
                downloaded={false}
                width="20"
                height="20"
                onClick={this.props.OnDownloadAlbumClicked}
              />
            </span>
          </div>

          {listItems}
          <div
            style={{
              clear: "left",
              height: "20px",
            }}
          />

          <span
            style={{
              clear: "left",
              display: "inline-block",
              padding: "5px",
              color: "#666666",
              fontSize: "0.75em",
              width: "100%",
            }}
          >
            {this.props.releaseDate}
            <br />
            {this.props.playtime}
            <br />
          </span>
          <span
            style={{
              clear: "left",
              display: "inline-block",
              padding: "5px",
              color: "#666666",
              fontSize: "0.5em",
            }}
          >
            <br />
            &copy; 2021 All songs Copyright Nick Vido. All Rights Reserved.
            <br />
            &copy; 2021 Music Player:
            <br />
            Github: <a href="https://github.com/nickvido">@nickvido</a>{" "}
            <a href="https://github.com/phillipcurl">@phillipcurl</a>
          </span>
        </div>
      </div>
    );
  }
}
