import React, { Component } from "react";
import styles from "./CurrentlyPlaying.module.css";
import { PlayPauseButton } from "../PlayPauseButton";
import { CoverImage } from "../CoverImage";

export class CurrentlyPlaying extends Component {
  render() {
    return (
      <div
        height={this.props.height}
        style={{
          background: "#222222",
          maxHeight: this.props.height,
          display: "flex",
          zIndex: "100",
          maxWidth: "inherit",
          position: "fixed",
          width: "100%",
          padding: "0px",
          bottom: 0,
          left: 0,
          right: 0,
          overflowY: "none",
          height: this.props.height + 2,
        }}
        onClick={this.props.OnCurrentlyPlayingClicked}
      >
        <CoverImage
          style={{ float: "left" }}
          image={this.props.cover}
          width={this.props.height}
          height={this.props.height}
        />
        <div
          style={{
            display: "inline-block",
            float: "left",
            width: "100%",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "10px",
            fontFamily: "sans-serif",
          }}
        >
          <span style={{ color: "white" }}>{this.props.trackTitle}</span>
          <br /> <span style={{ color: "gray" }}>{this.props.artist}</span>
        </div>
        <PlayPauseButton
          style={{
            float: "right",
            marginRight: "10px",
            marginTop: "10px",
            zIndex: "100",
          }}
          marginRight="20"
          isPlaying={this.props.isPlaying}
          OnPlayPauseButtonClicked={(e) => {
            e.stopPropagation();
            this.props.OnPlayPauseButtonClicked(e);
          }}
          width={this.props.height - 20}
          height={this.props.height - 20}
          fill={this.props.buttonColor}
        />
        <div
          style={{
            height: "2px",
            width: this.props.progress + "%",
            background: this.props.buttonColor,
            display: "inline-block",
            position: "fixed",
            maxWidth: "inherit",
            bottom: "50px",
            maxHeight: "2px",
            padding: "0px",
            left: 0,
            right: 0,
          }}
        />
      </div>
    );
  }
}
