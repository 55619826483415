import logo from './logo.svg';
import './App.css';
import { MobileMusicPlayer } from "./react-music-player/components/MobileMusicPlayer";
import cover from "./assets/twitter.jpg";
import artistImage from "./assets/nickvido.jpg";

const tracks = [
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+04+-+Tokyo+Subway+Platform+01+-+MIX+01.mp3",
    title: "Tokyo Subway Platform",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Stereotype",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 2",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 3",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 4",
    artist: "Nick Vido",
    downloaded: true,
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 5",
    artist: "Nick Vido",
    explicit: true,
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 6",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 7",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 8",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 9",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 10",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Another Track 11",
    artist: "Nick Vido",
  },
  {
    url:
      "https://s3.amazonaws.com/nickvido.com/media/2021+01+24+-+Stereotype+-+NO+MIX.mp3",
    title: "Last Track",
    artist: "Nick Vido",
  },
];

// add the cover image
tracks.forEach((track) => {
  track.coverImage = cover;
});

function App() {
  return (
    <div className="App">
      <MobileMusicPlayer
        defaultCoverImage={cover}
        albumTitle="ROUGH DRAFTS"
        artist="Nick Vido"
        releaseYear="2021"
        allowedAccess={true}
        tracks={tracks}
        showingTrack={false}
        artistImage={artistImage}
        releaseDate="April 1, 2021"
        playtime="1 hr, 25 min"
        bgcolor="#663399"
        buttonColor="#ff00d0"
        trackHighlightColor="#39ff14"
        OnDownloadTrackClicked={(e, title) => {
          console.log("OnDownloadTrackClicked " + title);
        }}
        OnLikeAlbumClicked={(e) => {
          console.log("OnLikeAlbumClicked");
        }}
        OnDownloadAlbumClicked={(e) => {
          console.log("OnDownloadAlbumClicked");
        }}
        OnArtistClicked={(e) => {
          console.log("OnArtistClicked");
        }}
      />
    </div>
  );
}



export default App;
