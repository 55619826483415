import React, { Component } from "react";
import styles from "./PlayPauseButton.module.css";
import { ReactComponent as PlayButton } from "../../assets/play-button.svg";
import { ReactComponent as PauseButton } from "../../assets/pause-button.svg";

export class PlayPauseButton extends Component {
  render() {
    if (this.props.isPlaying) {
      return (
        <div
          style={{            
            minWidth: this.props.width + this.props.marginRight,
            minHeight: this.props.height,            
            marginTop: "0px",            
          }}
          onClick={this.props.OnPlayPauseButtonClicked}
        >
          <PauseButton
            fill={this.props.fill}
            width={this.props.width}
            height={this.props.height}
            style={this.props.style}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{            
            minWidth: this.props.width + this.props.marginRight,
            minHeight: this.props.height,
            marginTop: "0px",                        
          }}
          onClick={this.props.OnPlayPauseButtonClicked}
        >
          <PlayButton
            fill={this.props.fill}
            width={this.props.width}
            height={this.props.height}
            style={this.props.style}
          />
        </div>
      );
    }
  }
}
