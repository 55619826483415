import React, { Component } from "react";
import styles from "./ExplicitIcon.module.css";
import { ReactComponent as ExplicitIconSvg } from "../../assets/explicit.svg";

export class ExplicitIcon extends Component {
  render() {
    return (
      <div
        style={{
          minWidth: this.props.width,
          minHeight: this.props.height,
        }}
        onClick={this.props.OnExplicitIconClicked}
      >
        <ExplicitIconSvg
          fill={this.props.fill}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
