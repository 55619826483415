import React, { Component } from "react";
import styles from "./Playlist.module.css";

export class PlaylistItem extends Component {
  render() {
    return (
      <div
        onClick={(e) => this.props.onClick(this.props.title, e)}
        key={this.props.title}
        song={this.props.title}
        cover={this.props.title}
        artist={this.props.title}
        className={this.props.isActive ? styles.Active : ""}
      >
        {this.props.title}
      </div>
    );
  }
}

export class Playlist extends Component {
  render() {
    const listItems = this.props.tracks.map((track) => {
      var isActive = false;
      if (this.props.currentTrack) {
        if (this.props.currentTrack.title === track.title) {
          isActive = true;
        }
      }

      return (
        <PlaylistItem
          onClick={this.props.onClick}
          key={track.title}
          title={track.title}
          song={track.song}
          artist={track.artist}
          isActive={isActive}
        />
      );
    });

    return (
      <div className={styles.PlaylistContainer} className="hidden">
        {listItems}
      </div>
    );
  }
}
