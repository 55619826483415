import React, { Component } from "react";
import styles from "./PrevButton.module.css";
import { ReactComponent as PrevButtonSvg } from "../../assets/next-svgrepo-com.svg";

export class PrevButton extends Component {
  render() {
    return (
      <div
        style={{
          transform: "rotate(180deg)",
          minWidth: this.props.width,
          minHeight: this.props.height,
        }}
        onClick={this.props.OnPrevButtonClicked}
      >
        <PrevButtonSvg
          fill={this.props.fill}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
