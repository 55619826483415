import React, { Component } from "react";
import styles from "./BackButton.module.css";
import { ReactComponent as BackButtonSvg } from "../../assets/left-arrow-next-svgrepo-com.svg";

export class BackButton extends Component {
  render() {
    return (
      <div
        style={{
          minWidth: this.props.width,
          minHeight: this.props.height,
        }}
        onClick={this.props.OnBackButtonClicked}
      >
        <BackButtonSvg
          fill={this.props.fill}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
