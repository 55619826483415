import "./CoverImage.css";

export const CoverImage = ({ image, width, height, style }) => {
  return (
    <div className="CoverImage" style={style}>
      <img
        src={image}
        alt="Album Cover"
        width={width}
        height={height}
        style={style}
      />
    </div>
  );
};

export default CoverImage;
