import React, { Component } from "react";
import styles from "./LikeButton.module.css";
import { ReactComponent as HeartSvg } from "../../assets/heart.svg";
import { ReactComponent as HeartOutlineSvg } from "../../assets/heart-outline.svg";

export class LikeButton extends Component {
  render() {
    if (this.props.liked) {
      return (
        <div
          style={{
            minWidth: this.props.width,
            minHeight: this.props.height,
          }}
          onClick={this.props.onClick}
        >
          <HeartSvg
            fill={this.props.fill}
            width={this.props.width}
            height={this.props.height}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            minWidth: this.props.width,
            minHeight: this.props.height,
          }}
          onClick={this.props.onClick}
        >
          <HeartOutlineSvg
            fill={this.props.fill}
            stroke={this.props.stroke}
            width={this.props.width}
            height={this.props.height}
          />
        </div>
      );
    }
  }
}
